import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// load language files
import locales from "../translations/_config";
import translation_en from '../translations/en.json';

// define the translations
const resources = {
	en: {
		translation: translation_en
	}
};

// configure i18n module
i18n
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: locales.default,
		lng: locales.default,
		debug: false,
		keySeparator: '.',
		interpolation: {
			escapeValue: true
		},
		react: {
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'sup']
		}
});

export default i18n;