import React from 'react';
import * as styles from './styles/_Wrapper.module.scss';
import i18n from '../../../libs/i18n'; //eslint-disable-line
import { StaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";

const Wrapper = ({ data, pageContext, children, ...props }) => {


    // render html
    return(
        <div className={styles.wrapper}>

            <StaticQuery
                query={graphql`
                    query LayoutQuery {
                    datoCmsSite {
                        globalSeo {
                        siteName
                        }
                        faviconMetaTags {
                        ...GatsbyDatoCmsFaviconMetaTags
                        }
                    }
                    }
                `}
                render={data => (
                    <>
                        <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags}>
                            <html lang="en" />
                            <meta charSet="utf-8" />
                        </HelmetDatoCms>
                    </>
                )}
            />


            <div className={styles.wrapper__inner}>
                {children}
            </div>

          

        </div>





    );

}

export default Wrapper;

